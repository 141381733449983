/** @format */

import { Grid } from "@mui/material";
import React, { useState } from "react";
import InputField from "../../../Components/Inputs/InputField";
import UploadField from "../../../Components/Inputs/UploadField/Index";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";
import SecondaryButton from "../../../Components/Inputs/secondaryButton/index";
import { colors } from "../../../Constants/theme";
import { Form, Formik } from "formik";
import { useGetAll } from "../../../Hooks/useGetAll";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateOrUpdate } from "../../../Hooks/useCreateOrUpdate"; // Import the hook
import { useAlert } from "../../../Components/Layout/Alerts/Index";
import TextEditor from "../../../Components/Inputs/TextEditor/Index";

function EditGallery() {
  const navigate = useNavigate();
  let { state } = useLocation();
  let { id } = state;
  const addAlert = useAlert();
  const { data: Gallery } = useGetAll({
    key: `/records/videos/${id}/nt/`,
    enabled: true,
    select: (data) => {
      return data.data;
    },
  });

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/records/videos/${id}/nt/`,
    method: "put",
    onSuccess: (response) => {
      addAlert(
        "Update video page  successfully!",
        "success",
        {
          vertical: "top",
          horizontal: "center",
        },
        3000
      );
      navigate(-1);
    },
    onError: (error) => {
      addAlert(
        `${error}`,
        "error",
        {
          vertical: "top",
          horizontal: "center",
        },
        3000
      );
    },
  });

  // console.log(Gallery.url, "=====>url");

  const initialValues = {
    id: Gallery?.id || "",
    title: Gallery?.title || "",
    url: Gallery?.url || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        const formData = new FormData();
        // formData.append("image", values.image[0]);
        formData.append("url", values.url);

        formData.append("title", values.title);
        mutate(formData);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Grid container spacing={{ xs: 2, lg: 4 }}>
            <Grid item xs={12} sm={6}>
              <InputField
                disabled
                name='id'
                label='Id'
                placeholder='Enter image id'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name='title'
                label='Image Title'
                placeholder='Enter video title'
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                label='Video Description and Url'
                value={values?.url}
                name='url'
                onChange={(data) => setFieldValue("url", data)}
                placeholder='Type something here'
                rows={10}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mt={{ xs: 2, md: 0 }}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={4}
            >
              <SecondaryButton
                onClick={() => navigate(-1)}
                className='w-[69px] h-[32px] border border-[#082F49]'
                sx={{
                  height: "44px",
                  width: "120px",
                  border: `1px solid ${colors.primary.dark}`,
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                isLoading={isLoading}
                sx={{
                  height: "44px",
                  width: "120px",
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
                type='submit'
              >
                Save
              </PrimaryButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default EditGallery;
