/** @format */

import { Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import InputField from "../../../Components/Inputs/InputField";
import RadioGroup from "../../../Components/Inputs/radioGroup";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";
import SecondaryButton from "../../../Components/Inputs/secondaryButton";
import { colors } from "../../../Constants/theme";
import { Form, Formik } from "formik";
import { useCreateOrUpdate } from "../../../Hooks/useCreateOrUpdate";
import * as yup from "yup";
import { useGetAll } from "../../../Hooks/useGetAll";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../../Components/Layout/Alerts/Index";
import SelectField from "../../../Components/Inputs/SelectField/Index";

function EditStats() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  let { state } = useLocation();
  let { id } = state;
  const addAlert = useAlert();
  const phone = useMediaQuery("(max-width:751px)");

  const validationSchema = yup.object().shape({
    s_name: yup.string().required("Sponsor name is required"),
    no_of_students: yup.number().required("Number is required"),
    since: yup.date().required(" is required"),
    end_date: yup.date().required(" is required"),

    address: yup.string().required("is required"),
    // donation_type: yup.required("is required"),
  });
  const { data: sponsor } = useGetAll({
    key: `/records/sponsors/${id}/nt/`,
    select: (data) => data?.data,
    onSuccess: (data) => {
      console.log(data, "<----------data");
      setData(data);
    },
  });
  const initialValues = {
    s_name: data?.s_name || "",
    since: data?.since || "",
    end_date: data?.end_date || "",
    donation_type: data?.donation_type || "",
    address: data?.address || "",
    no_of_students: data?.no_of_students || "",
  };

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/records/sponsors/${id}/nt/`,
    method: "put",
    onSuccess: (response) => {
      addAlert(
        "Update stats  successfully!",
        "success",
        {
          vertical: "top",
          horizontal: "center",
        },
        3000
      );
      navigate(-1);
    },
    onError: (error) => {
      addAlert(
        `${error}error`,
        "error",
        {
          vertical: "top",
          horizontal: "center",
        },
        3000
      );
    },
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const formData = new FormData();
        formData.append("s_name", values.s_name);
        formData.append("since", values.since);
        formData.append("end_date", values.end_date);
        formData.append("donation_type", values.donation_type.value);
        formData.append("address", values.address);
        formData.append("no_of_students", values.no_of_students);
        mutate(formData);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Grid container spacing={{ xs: 2, lg: 4 }}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='s_name'
                label='Sponsor Name'
                placeholder='Enter title name'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='since'
                label='Start Date'
                type={"date"}
                placeholder='Enter Date'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='end_date'
                type={"date"}
                label='End Date'
                placeholder='Enter Date'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='no_of_students'
                label='Number of students'
                type={"number"}
                placeholder='Enter number of students'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='address'
                label='Address'
                placeholder='Enter address'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField
                name='donation_type'
                options={[
                  { label: "Monthly", value: "Monthly" },
                  { label: "Half yearly", value: "Half-Yearly" },
                  { label: "Yearly", value: "Yearly" },
                ]}
                label='Donation Type'
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root ": {
                    height: phone ? "45px" : "",
                  },
                }}
                placeholder='Donation type'
                value={values.donation_type}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={4}
            >
              <SecondaryButton
                onClick={() => {
                  navigate(-1);
                }}
                className='w-[69px] h-[32px] border border-[#082F49]'
                sx={{
                  height: "44px",
                  width: "100px",
                  border: `1px solid ${colors.primary.dark}`,
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                isLoading={isLoading}
                sx={{
                  height: "44px",
                  width: "100px",
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
                type='submit'
              >
                Save
              </PrimaryButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default EditStats;
