/** @format */

import React from "react";
import NavbarCustom from "../../Components/Layout/Navbar/NavbarCustom";
import Footer from "../../Components/Layout/Footer/Index";
import AboutUsHeaderimg from "../../Components/Layout/AboutUsHeaderImg/Index";
import TabBox from "../../Components/Layout/TabBox/Index";
import { chipColors, colors } from "../../Constants/theme";
import { Helmet } from "react-helmet";

function Index() {
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title> Overview - Jamiatul Irshad</title>
        {/* <link rel='canonical' href='http://mysite.com/example' /> */}
      </Helmet>
      <NavbarCustom />
      <div className='w-full h-[488px] max-desktop:h-[375px] flex items-end'>
        <AboutUsHeaderimg />
      </div>
      <div className='w-[79.17%] max-desktop:w-[90%] m-auto pb-[100px] pt-[80px] max-tablet:pt-[40px]'>
        <div
          className='w-full flex flex-col items-center gap-[20px] text-[1.125rem] max-tablet:text-[1rem] text-justify font-normal font-roboto '
          style={{ color: colors.text.dark }}
        >
          <h1
            className='font-roboto text-[2.25rem] font-extrabold max-tablet:text-[1.5rem] max-desktop:text-[1.875rem]  '
            style={{ color: colors.text.light }}
          >
            About us
          </h1>
          <p>
            AL IRSHAD EDUCATIONAL AND WELFARE TRUST was founded on February 20,
            2021, by Qari Muhammad Mushahid and Maulana Muhammad Naushad. It was
            established with a clear vision to provide quality Deeni (religious)
            education along with modern academic learning, ensuring that Muslim
            boys and girls are equipped with both Islamic knowledge and
            contemporary education. The founders envisioned a madrasa that would
            cater to the religious, intellectual, and academic needs of the
            students, preparing them for both worldly and spiritual success.
          </p>
          <p>
            The madrasa offers a diverse range of subjects. In terms of
            religious education, students are taught Nazra and Hifz Qur'an,
            Tajweed o Qirat, Basic Islamic Law, and Naat and Bayan practice. In
            addition to these core religious subjects, the madrasa also offers
            modern education up to the 6th grade, including subjects like
            science and basic computer knowledge. Currently, there are 213
            students enrolled at the madrasa, ranging in age from 6 to 17 years.
            The madrasa's programs are designed to accommodate students at
            different stages, with Nazra classes for younger students aged 6 to
            10, and Hifz programs for older students between the ages of 10 and
            17.
          </p>
          <p>
            The madrasa is supported by a dedicated team of educators, including
            6 teachers and 6 staff members. The faculty brings a mix of
            qualifications, with some teachers holding degrees in Islamic
            studies, such as Qari and Mufti certifications, while others have
            completed modern education degrees like B.Ed, M.Com, and M.Sc. This
            blend of traditional and modern qualifications allows the madrasa to
            offer a balanced curriculum to its students.
          </p>
          <p>
            In terms of infrastructure, the madrasa has 5 classrooms to
            facilitate learning and a modest library with over 157 books to
            support students in their studies. Although there is no dedicated
            computer lab, the madrasa strives to provide essential educational
            tools. There is a prayer hall where students and staff gather for
            daily prayers, although it is not a formal mosque.
          </p>
          <p>
            To support the well-being of its students, the madrasa provides
            meals throughout the day, offering breakfast, lunch, and dinner. In
            addition to academic and spiritual growth, the madrasa encourages
            physical activity through a playground and offers laundry services
            to ensure the students' personal hygiene is maintained.{" "}
          </p>
          <p>
            While AL IRSHAD EDUCATIONAL AND WELFARE TRUST has not yet
            participated in external competitions or received any formal awards,
            it remains committed to serving the community. The madrasa organizes
            medical camps for the local population, extending its role beyond
            education to serve the broader society. The institution is focused
            on continuous improvement, aiming to offer more facilities and
            resources in the future, with the ultimate goal of nurturing
            well-rounded, knowledgeable individuals who contribute positively to
            society.{" "}
          </p>
        </div>
        <div className='w-full mt-[60px] max-tablet:w-full  m-auto'>
          <TabBox />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
