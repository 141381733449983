/** @format */

import React from "react";
import PrivacyAndTerms from "../../Components/Layout/PrivacyAndTerms/Index";
import { Helmet } from "react-helmet";
const data = [
  {
    PageTitle: "pricing & refunds POLICY",
    dateTime: "Wed Jul 03 2024 16:54:36",
    standardTime: " GMT+0530 (India Standard Time)",
  },
  {
    title: "Simple Fees, Services at Large",
    content:
      "Administrative Fees: 3% of the total donation raised goes towards our administrative development and expenses.",
    contentPoints: [
      {
        point:
          "Payment Gateway Fees: An additional 2% for the local payment gateway is applicable on the donation raised.",
      },
      {
        point:
          "Total Fees: A total of 5% fee of the total donation raised is applicable.",
      },
      {
        point:
          "Taxes: GST of 18% will be charged additionally on the Platform Fee and Payment Gateway fee.",
      },
      {
        point:
          "Currency and Payments: Payments are allowed only in INR and from bank accounts within India.",
      },
      {
        point:
          "Queries: If you have any queries, feel free to ask by emailing us at: admin@alirshadtrust.org",
      },
    ],
  },

  {
    title: "Cancellation and Refund Policy",
    content:
      "Donations and payments made through AL IRSHAD EDUCATIONAL AND WELFARE TRUST are final and cannot be refunded, except in accordance with the AL IRSHAD EDUCATIONAL AND WELFARE TRUST Refund Policy. However, AL IRSHAD EDUCATIONAL AND WELFARE TRUST will issue refunds for the following exceptional scenarios",
    contentPoints: [
      {
        point:
          "Fraudulent Campaigns: A campaign has been identified as fraudulent or violating the terms of service and no donations have yet been transferred to the recipient/beneficiary. In such cases, AL IRSHAD EDUCATIONAL AND WELFARE TRUST will decide to refund all donations received to the respective donors.",
      },
      {
        point:
          "Campaign Cancellation: The campaign creator has posted an update that the intended purpose of the fundraising campaign is no longer possible, and no donations have yet been transferred to the recipient/beneficiary. In such cases, refunds will be issued.",
      },
      {
        point:
          "Management Discretion: Any other claims for refunds shall be honored at the discretion of the AL IRSHAD EDUCATIONAL AND WELFARE TRUST Management Team.",
      },
    ],
  },
  {
    title: "Refund Processing",

    contentPoints: [
      {
        point:
          "Refund Method: All refunds will be credited to the original mode of payment.",
      },
      {
        point:
          "Refund Time-frame: Refunds will be processed within 12-15 bank working days.",
      },
    ],
  },
];

function Index() {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title> Refund Policy - Jamiatul Irshad</title>
        {/* <link rel='canonical' href='http://mysite.com/example' /> */}
      </Helmet>
      <PrivacyAndTerms data={data} />;
    </>
  );
}

export default Index;
