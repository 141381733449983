/** @format */

import React, { useState, useEffect } from "react";
import Table from "../../../Components/Table/Index";
import { useGetAll } from "../../../Hooks/useGetAll";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";
import { Link } from "react-router-dom";

function Index() {
  const [rows, setRows] = useState([]);

  const [limit, SetLimit] = useState(10);
  const { data: enquiryLimit, refetch } = useGetAll({
    key: `/payment/add-donation/`,
    select: (data) => data, // Ensure data is an array
    onSuccess: (data) => {
      SetLimit(data?.data?.total_count);
      // console.log(data, "<----------dataCount");
    },
  });
  const { data: enquiry } = useGetAll({
    key: `/records/contactus/nt/?limit=${limit}`,
    select: (data) => data?.data?.rows,
    onSuccess: (data) => {
      setRows(mapApiDataToTableRows(data));
    },
  });

  const mapApiDataToTableRows = (apiData) => {
    // console.log(apiData, "======>ApiData");
    return (
      apiData
        // .filter((item) => item.user_id) // Filter out rows without exp_id
        ?.map((item, index) => ({
          id: item.id, // Use exp_id or fallback to a unique index-based ID

          // user_id: item.user_id,
          name: item.name,
          email: item.email,

          feedback: item.feedback,
        }))
    );
  };

  const columns = [
    { field: "name", headerName: "Name", width: 130 },
    {
      field: "email",
      headerName: "Email",
    },

    {
      field: "feedback",
      headerName: "Feedback",
      width: 160,
    },
    {
      field: "actions",
      headerName: "View ",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Link to='View' state={{ id: params?.id }}>
          <PrimaryButton
            sx={{
              borderRadius: 1,
              height: "30px",
              background: "#115e59",
              "&:hover": {
                background: "#0e8073",
              },
            }}
          >
            View
          </PrimaryButton>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (enquiry) {
      setRows(mapApiDataToTableRows(enquiry));
    }
  }, [enquiry]);

  return (
    <div className=''>
      <Table
        columns={columns}
        title='Enquiries'
        rows={rows}
        DeleteUrl={`/records/delete-multiple-contactus/`}
        refetchUrl={`/records/contactus/nt/?limit=${limit}`}
      />
    </div>
  );
}

export default Index;
