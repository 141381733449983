/** @format */

import React, { useEffect, useState, useRef } from "react";
import NavbarCustom from "../../Components/Layout/Navbar/NavbarCustom";
import Swiper from "../../Components/Layout/Swiper/Swiper";
import Dashboard from "../../Components/Layout/Dashboard/Index";
import Footer from "../../Components/Layout/Footer/Index";
import Image from "../../Constants/Image";
import Card1 from "../../Components/Layout/landingCard/LandingCard";
import Timeline from "../../Components/Layout/Timeline/Index";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import serverAPI from "../../config/serverAPI";
import { useAlert } from "../../Components/Layout/Alerts/Index";

const cardData = [
  {
    title: "EMPOWERING MINDS ENRICHING SOULS",
    text1:
      "At our Madarasa, we blend traditional Islamic teachings with modern education to cultivate well-rounded individuals.",
    text2:
      "Our experienced educators, supportive community, and holistic approach ensure that students not only excel academically but also grow spiritually and morally.",
    text3:
      "Choose us for a nurturing environment where every child is valued and guided to achieve their full potential.",
    img: Image.LandingCardImg3,
  },
  {
    title: "Inspiring Future Generations",
    text1:
      "Our vision is to create a vibrant learning community where Islamic values and contemporary knowledge coexist harmoniously.",
    text2:
      "We aim to produce individuals who are not only academically proficient but also ethically sound, contributing positively to society.",
    text3:
      "By fostering an environment of continuous learning and personal growth, we aspire to be a beacon of excellence in Islamic and secular education.",
    img: Image.LandingCardImg,
  },
  {
    title: "Committed to Excellence",
    text1:
      "At our Madarasa, we blend traditional Islamic teachings with modern education to cultivate well-rounded individuals.",
    text2:
      "Our experienced educators, supportive community, and holistic approach ensure that students not only excel academically but also grow spiritually and morally.",
    text3:
      "Choose us for a nurturing environment where every child is valued and guided to achieve their full potential.",
    img: Image.Empowering,
  },
  {
    title: "Guidance Towards Success",
    text1:
      "Our primary aim is to offer a balanced education that caters to the intellectual, spiritual & emotional needs of our students.",
    text2: "Our objectives include:",
    text3: [
      "Delivering high-quality Islamic and secular education.",
      "Encouraging a deep understanding and practice of Islamic values.",
      "Promoting academic excellence and lifelong learning.",
      "Fostering a sense of community and social responsibility.",
      "Preparing students to face future challenges with confidence.",
    ],
    img: Image.LandingCardImg2,
  },
];

function Home() {
  const recaptchaRef = useRef(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Function to check if the token has expired (updated to 24 hours)
  const checkTokenExpiry = () => {
    const storedTime = localStorage.getItem("captchaTimestampAlirshad");
    if (storedTime) {
      const currentTime = Date.now();
      const timeElapsed = (currentTime - storedTime) / (1000 * 60 * 60); // Time elapsed in hours
      if (timeElapsed > 24) {
        // Clear stored values if more than 24 hours have passed
        localStorage.removeItem("captchaVerifiedAlirshad");
        localStorage.removeItem("captchaTimestampAlirshad");
        setCaptchaVerified(false);
      } else {
        setCaptchaVerified(true);
      }
    }
  };

  // Store timestamp after verification (no change needed here)
  const handleCaptchaChange = async (captchaValue) => {
    if (!captchaValue) return;

    setLoading(true);
    try {
      const response = await serverAPI.post("/captcha-verify/", {
        token: captchaValue,
      });

      if (response.data.success) {
        setCaptchaVerified(true);
        localStorage.setItem("captchaVerifiedAlirshad", "true");
        localStorage.setItem("captchaTimestampAlirshad", Date.now());
        // addAlert(`${response.data.message}`, "success");
      } else {
        recaptchaRef.current?.reset(); // Reset captcha only if unsuccessful
        throw new Error(response.data.message);
      }
    } catch (error) {
      recaptchaRef.current?.reset(); // Reset if there's an error
      setErrorMessage("Verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Call the `checkTokenExpiry` function during the initial render
  useEffect(() => {
    checkTokenExpiry();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Home - Jamiatul Irshad</title>
      </Helmet>

      {!captchaVerified ? (
        <div className='flex justify-center items-center h-screen'>
          <div>
            <h2 className='text-xl mb-4'>Please verify you are not a robot</h2>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={handleCaptchaChange}
              ref={recaptchaRef}
            />
            {loading && <p className='mt-4'>Verifying...</p>}
            {errorMessage && (
              <p className='mt-4 text-red-500'>{errorMessage}</p>
            )}
          </div>
        </div>
      ) : (
        <>
          <NavbarCustom />
          <Swiper />
          <Dashboard />
          {cardData.map((item, index) => (
            <Card1
              key={index}
              title={item.title}
              text1={item.text1}
              text2={item.text2}
              text3={item.text3}
              img={item.img}
              imageOnLeft={index % 2 === 1}
            />
          ))}
          <Timeline />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;
