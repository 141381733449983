/** @format */

import React from "react";
import PrivacyAndTerms from "../../Components/Layout/PrivacyAndTerms/Index";
import { Helmet } from "react-helmet";
const data = [
  {
    PageTitle: "Terms & Conditions",
    dateTime: "Mon Sept 23 2024 13:08:36",
    standardTime: "  GMT+0530 (India Standard Time)",
  },
  {
    title: "Welcome",
    content: `Welcome to  AL IRSHAD EDUCATIONAL AND WELFARE TRUST ("we," "us," or "our"). By accessing or using our digital platform and related services (collectively, the "Services"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using our Services.`,
  },

  {
    title: "1. Electronic Record Notice",
    content: `This document is an electronic record in terms of Information Technology Act, 2000 and rules
      there under as applicable and the amended provisions pertaining to electronic records in various
      statutes as amended by the Information Technology Act, 2000. This electronic record is generated
      by a computer system and does not require any physical or digital signatures.`,
  },
  {
    title: "2. Governing Laws and Regulations",
    content: `This document is published in accordance with the provisions of Rule 3 (1) of the Information
      Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
      regulations, privacy policy and Terms of Use for access or usage of domain name https://www.alirshadtrust.org/ 
      ('Website'), including the related mobile site and mobile application
      (hereinafter referred to as 'Platform').`,
  },
  {
    title: "3. Platform Ownership",
    content: `The Platform is owned by AL IRSHAD EDUCATIONAL AND WELFARE TRUST, a
    company incorporated under the Companies Act, 1956 with its registered office at Saharanpur ,
    India (hereinafter referred to as ‘Platform Owner’, 'we', 'us', 'our')..`,
    contentPoints: [
      {
        point:
          "Eligibility: You must be at least 18 years old to use our Services. If you are under the age of 18, you may only use our Services with the consent and supervision of a parent or legal guardian",
      },
      {
        point:
          "Account Registration: To use certain features of our Services, you may need to create an account. You agree to provide accurate, complete, and current information during the registration process and to update such information to keep it accurate, complete, and current.",
      },
      {
        point:
          " User Conduct: You agree not to use our Services for any unlawful or prohibited purpose, and you will comply with all applicable laws and regulations. You further agree not to:",
      },
      {
        point:
          "Engage in any activity that interferes with or disrupts our Services or networks. Upload, transmit, or distribute any content that is harmful, abusive, defamatory, obscene, or otherwise objectionable.",
      },

      {
        point:
          "Engage in any fraudulent, deceptive, or misleading behavior. Impersonate any person or entity or falsely claim an affiliatio  with any person or entity.",
      },
    ],
  },
  {
    title: "4. Acceptance of Terms",
    content: `Your use of the Platform and services and tools are governed by the following terms and
    conditions (“Terms of Use”) as applicable to the Platform including the applicable policies which
    are incorporated herein by way of reference. If You transact on the Platform, You shall be subject
    to the policies that are applicable to the Platform for such transaction. By mere use of the Platform,
    You shall be contracting with the Platform Owner and these terms and conditions including the
    policies constitute Your binding obligations, with Platform Owner. These Terms of Use relate to
    your use of our website, goods (as applicable) or services (as applicable) (collectively, 'Services').
    Any terms and conditions proposed by You which are in addition to or which conflict with these
    Terms of Use are expressly rejected by the Platform Owner and shall be of no force or effect.
    These Terms of Use can be modified at any time without assigning any reason. It is your
    responsibility to periodically review these Terms of Use to stay informed of updates..`,
  },
  {
    title: "5. Modification of Terms",
    content: `For the purpose of these Terms of Use, wherever the context so requires ‘you’, 'your' or ‘user’ shall
    mean any natural or legal person who has agreed to become a user/buyer on the Platform..`,
  },
  {
    title: "6.User Definition",
    content: `ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR
      AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
      SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING..`,
  },
  {
    title: "7.Agreement to Terms",
    content: `The use of Platform and/or availing of our Services is subject to the following Terms of Use:`,
    contentPoints: [
      {
        point: `To access and use the Services, you agree to provide true, accurate and complete information
      to us during and after registration, and you shall be responsible for all acts done through the
      use of your registered account on the Platform..`,
      },
      {
        point: `Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
            timeliness, performance, completeness or suitability of the information and materials offered
            on this website or through the Services, for any specific purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we expressly exclude
            liability for any such inaccuracies or errors to the fullest extent permitted by law..`,
      },
      {
        point: `Your use of our Services and the Platform is solely and entirely at your own risk and
      discretion for which we shall not be liable to you in any manner. You are required to
      independently assess and ensure that the Services meet your requirements..`,
      },
      {
        point: `The contents of the Platform and the Services are proprietary to us and are licensed to us.
      You will not have any authority to claim any intellectual property rights, title, or interest in
      its contents. The contents includes and is not limited to the design, layout, look and graphics..`,
      },
      {
        point: `You acknowledge that unauthorized use of the Platform and/or the Services may lead to
        action against you as per these Terms of Use and/or applicable laws..`,
      },
      {
        point: `You agree to pay us the charges associated with availing the Services..`,
      },
      {
        point: `You agree not to use the Platform and/ or Services for any purpose that is unlawful, illegal or
          forbidden by these Terms, or Indian or local laws that might apply to you.`,
      },
      {
        point: `You agree and acknowledge that website and the Services may contain links to other third
          party websites. On accessing these links, you will be governed by the terms of use, privacy
          policy and such other policies of such third party websites. These links are provided for your
          convenience for provide further information..`,
      },
      {
        point: `You understand that upon initiating a transaction for availing the Services you are entering
        into a legally binding and enforceable contract with the Platform Owner for the Services..`,
      },
      {
        point: `You shall indemnify and hold harmless Platform Owner, its affiliates, group companies (as
          applicable) and their respective officers, directors, agents, and employees, from any claim or
          demand, or actions including reasonable attorney's fees, made by any third party or penalty
          imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other
          Policies, or Your violation of any law, rules or regulations or the rights (including
          infringement of intellectual property rights) of a third party.`,
      },
      {
        point: `Notwithstanding anything contained in these Terms of Use, the parties shall not be liable for
          any failure to perform an obligation under these Terms if performance is prevented or
          delayed by a force majeure event..`,
      },
      {
        point: `These Terms and any dispute or claim relating to it, or its enforceability, shall be governed
          by and construed in accordance with the laws of India..`,
      },
      {
        point: `All disputes arising out of or in connection with these Terms shall be subject to the exclusive
      jurisdiction of the courts in Saharanpur and Uttar Pradesh.`,
      },
      {
        point: `All concerns or communications relating to these Terms must be communicated to us using
        the contact information provided on this website`,
      },
    ],
  },
];

function Index() {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Terms and Condition - Jamiatul Irshad</title>
        {/* <link rel='canonical' href='http://mysite.com/example' /> */}
      </Helmet>
      <PrivacyAndTerms data={data} />
    </>
  );
}

export default Index;
