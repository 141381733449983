/** @format */

import React from "react";
import Footer from "../Footer/Index";

import NavbarCustom from "../Navbar/NavbarCustom";
import Image from "../../../Constants/Image";
import { colors } from "../../../Constants/theme";

function Index({ data, mb }) {
  return (
    <div>
      <NavbarCustom />

      <div className='w-[79.17%] max-desktop:w-[90%] m-auto pb-[100px] pt-[150px] max-tablet:pt-[110px]'>
        <h1
          className='mb-16 font-roboto uppercase   font-extrabold text-[2.25rem] w-full text-center max-desktop:text-[1.875rem] max-tablet:text-[1.5rem]'
          style={{ color: colors.text.light }}
        >
          {data[0].PageTitle}
        </h1>
        <div className='w-full space-y-[100px] max-desktop:space-y-[80px] max-tablet:space-y-[60px]'>
          {data.slice(1).map((item, index) => {
            return (
              <div
                key={index}
                className='space-y-2 max-desktop:space-y-2 max-tablet:space-y-1 font-roboto'
                style={{ color: colors.text.dark }}
              >
                <div className='flex gap-2 max-desktop:gap-2 max-tablet:gap-1 items-center'>
                  <img
                    src={Image.BulletPoint}
                    alt=''
                    className='w-[27px] h-[33px] max-desktop:w-[33px] max-tablet:w-[21px]'
                  />
                  <h1 className='text-[1.75rem] leading-7  max-tablet:text-[1.5rem] font-bold max-tablet:leading-5 '>
                    {item?.title}
                  </h1>
                </div>
                <p className='text-[1.375rem] text-justify max-desktop:text-[1.125rem] max-tablet:text-[1rem] leading-[33px] max-desktop:leading-[28px] max-tablet:leading-[23px]'>
                  {item?.content}
                </p>
                <ul className='text-[1.375rem] text-justify max-desktop:text-[1.125rem] max-tablet:text-[1rem] leading-[33px] max-desktop:leading-[28px] max-tablet:leading-[28px] list-disc ml-5'>
                  {item?.contentPoints?.map((item, index) => {
                    return <li key={index}>{item.point}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div
          className={`w-full justify-end mt-24  text-justify text-[1.125rem] leading-6 font-roboto font-normal `}
          style={{ color: colors.text.dark }}
        >
          <p className='font-bold'>Last Updated</p>
          <p>{data[0]?.dateTime}</p>
          <p>{data[0]?.standardTime}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
