/** @format */

import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { FromLabel } from "@mui/material";
import TimelineDot from "@mui/lab/TimelineDot";
import { useMediaQuery } from "@mui/material";
import PrimaryButton from "../../Inputs/PrimaryButton";
import { TimelineOppositeContent } from "@mui/lab";
import { colors } from "../../../Constants/theme";
import { Link } from "react-router-dom";

// const lastTimelineSeparatorStyle = {
//   height: "200px !important",
// };

const data = [
  {
    title: "Student Scholarships",
    content:
      "Providing financial assistance to underprivileged students who cannot afford tuition fees, books, and other educational resources.",
  },
  {
    title: "Educational Resources",
    content:
      "Purchasing books, technology, and other learning materials to enhance the educational experience of students.",
  },
  {
    title: "Facility Improvements",
    content:
      "Funding for the renovation and maintenance of school buildings, classrooms, and libraries to create a better learning environment.",
  },
  {
    title: "Teacher Training Programs",
    content:
      "Investing in professional development programs for teachers to ensure they are equipped with the latest educational techniques and methodologies.",
  },
  {
    title: "Orphan & Needy Student Support",
    content:
      "Providing comprehensive support to orphans and students from low-income families, covering their education, meals, clothing, and accommodation.",
  },
];

export default function AlternateTimeline() {
  const tablet = useMediaQuery("(max-width:1100px)");
  const phone = useMediaQuery("(max-width:751px)");

  const style = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "70px",
    background: colors.tertiary.gradient,
    "& .MuiTimelineItem-root .MuiTimelineSeparator-root .MuiTimelineDot-root": {
      background: colors.text.dark,
      width: "1.5rem",
      height: "1.5rem",
    },
    "& .MuiTimelineItem-root .MuiTimelineSeparator-root .MuiTimelineConnector-root":
      {
        width: "7px",
        height: "120px",

        background: colors.secondary.main,
      },

    "& .MuiTimelineItem-root::before": {
      display: tablet ? "none" : "",
    },
  };
  return (
    <Timeline sx={style} position={tablet ? "right" : "alternate"}>
      <div className='w-[935px] max-desktop:w-full max-desktop:px-24 px-0 max-tablet:w-full max-tablet:px-2'>
        <div className='w-full flex my-24 max-desktop:my-20 max-tablet:my-16 justify-center  relative'>
          <h1
            className={`text-[3.75rem] max-desktop:text-[2.575rem] max-tablet:text-[2.325rem] uppercase font-playfair font-black  text-[${colors.text.dark}]`}
          >
            Donate
            <span className='text-[2rem] max-desktop:text-[1.7rem] max-tablet:text-[1.35rem] font-playfair italic'>
              {" "}
              to a
            </span>{" "}
            <br />
            <span className='text-[#11998A] text-[3.2rem] max-desktop:text-[2.3rem] max-tablet:text-[2.05rem] absolute max-tablet:top-8 max-desktop:top-9 top-14 max font-black font-playfair '>
              good cause
            </span>
          </h1>
        </div>
        {data.map((item) => {
          return (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <h1
                  className={`text-[1.75rem] leading-[22px] mt-1 max-desktop:text-[1.5rem] max-tablet:text-[1.375rem] font-roboto text-[${colors.text.dark}] font-black`}
                >
                  {item.title}
                </h1>
                <p
                  className={`text-[1.12rem] leading-[22px] max-desktop:text-[1.125rem] max-tablet:text-[0.875rem]  font-light text-[${colors.text.light}] font-roboto`}
                >
                  {item.content}
                </p>
              </TimelineContent>
            </TimelineItem>
          );
        })}

        {tablet ? (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineContent>
                <div className='flex w-full items-center max-desktop:-mt-14 max-tablet:-mt-14 max-tablet:ml-4 ml-4'>
                  <div
                    className='w-[200px] max-tablet:w-[100px] h-[10px] '
                    style={{
                      background:
                        " linear-gradient(370deg, #14B2A1 0%, #0B665C 100%)",
                    }}
                  ></div>
                  <PrimaryButton
                    sx={{
                      minWidth: "160px !important",
                      width: phone ? "" : "200px",
                      height: "50px",
                      color: "#5EEAD4",
                      background: "#333333",
                      borderRadius: "10px",
                      fontFamily: "roboto",
                      fontWeight: 900,
                      fontSize: phone ? "1.1rem" : "1.375rem",
                    }}
                  >
                    HELP US
                  </PrimaryButton>
                </div>
              </TimelineContent>
            </TimelineSeparator>
          </TimelineItem>
        ) : (
          <TimelineItem>
            <TimelineSeparator>
              <Link to={"/account/ongoing-campaigns"} className='w-full'>
                <PrimaryButton
                  sx={{
                    width: "200px",
                    height: "50px",
                    color: colors.text.main,
                    background: colors.text.dark,
                    borderRadius: "10px",
                    fontFamily: "roboto",
                    fontWeight: 900,
                    fontSize: "1.375rem",
                  }}
                >
                  HELP US
                </PrimaryButton>
              </Link>
            </TimelineSeparator>
            <TimelineContent></TimelineContent>
          </TimelineItem>
        )}
      </div>
    </Timeline>
  );
}
