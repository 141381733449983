/** @format */

import React from "react";
import PrimaryButton from "../../Inputs/PrimaryButton";
import InputField from "../../Inputs/InputField";
import { Form, Formik } from "formik";
import { Dialog } from "../../Layout/dialogBox/dialog";
import { Money } from "@carbon/icons-react";
import { useCreateOrUpdate } from "../../../Hooks/useCreateOrUpdate";
import { colors } from "../../../Constants/theme";
import SecondaryButton from "../../Inputs/secondaryButton";
import * as yup from "yup";
import RadioGroup from "../../Inputs/radioGroup";
import { useAlert } from "../../Layout/Alerts/Index";
import { Grid } from "@mui/material";

const validationSchema = yup.object().shape({
  exp_english_title: yup.string().required("Title name is required"),
  exp_amount: yup.number().required("Number is required"),
  exp_urdu_title: yup.string().required("Title name is required"),
});

function AdminPanelDialog({ onSuccess }) {
  const { mutate, isLoading } = useCreateOrUpdate({
    url: "/records/expenses/nt/",
    method: "post",
  });
  const addAlert = useAlert();

  return (
    <div>
      <Dialog
        title={"Add Expense"}
        onClose={() => {}}
        button={
          <PrimaryButton
            sx={{
              height: "45px",
              fontSize: "1rem",
              borderRadius: "8px",
              fontWeight: 500,
              color: colors.text.main,
            }}
          >
            Add Expenses <Money size={20} className='ms-1 text-white' />
          </PrimaryButton>
        }
      >
        {({ onClose }) => (
          <Formik
            initialValues={{
              exp_english_title: "",
              exp_amount: "",
              exp_urdu_title: "",
              balance_amount: "",
              is_urgent: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              formData.append("exp_english_title", values.exp_english_title);
              formData.append("exp_urdu_title", values.exp_urdu_title);
              formData.append("exp_amount", values.exp_amount);
              formData.append("balance_amount", values.balance_amount);
              formData.append("is_urgent", values.is_urgent);

              mutate(formData, {
                onSuccess: (response) => {
                  addAlert(
                    "Add expense successfully!",
                    "success",
                    {
                      vertical: "top",
                      horizontal: "center",
                    },
                    3000
                  );
                  onSuccess();
                  onClose();
                },
                onError: (error) => {
                  addAlert(
                    `${error}error`,
                    "error",
                    {
                      vertical: "top",
                      horizontal: "center",
                    },
                    3000
                  );
                },
              });
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Grid container spacing={{ xs: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name='exp_english_title'
                      label='Title Name (english)'
                      placeholder='Enter title name'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name='exp_urdu_title'
                      label='(urdu)عنوان کا نام'
                      placeholder='Enter title name'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name='exp_amount'
                      type={"number"}
                      label='Amount'
                      placeholder='Enter Amount'
                    />
                  </Grid>
                  {values.is_urgent && (
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name='balance_amount'
                        type={"number"}
                        label='Balance Amount'
                        placeholder='Enter Amount'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <RadioGroup
                      name='is_urgent'
                      value={values.is_urgent}
                      onChange={(e) => {
                        setFieldValue("is_urgent", e.target.value === "true");
                      }}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      label={"Pending"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={4}
                  >
                    <SecondaryButton
                      onClick={onClose}
                      className='w-[69px] h-[32px] border border-[#082F49]'
                      sx={{
                        height: "44px",
                        width: "100px",
                        border: `1px solid ${colors.primary.dark}`,
                        borderRadius: "5px",
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      isLoading={isLoading}
                      sx={{
                        height: "44px",
                        width: "100px",
                        borderRadius: "5px",
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                      type='submit'
                    >
                      Save
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </div>
  );
}

export default AdminPanelDialog;
