/** @format */

import React, { useEffect, useState } from "react";
import NavbarCustom from "../../Components/Layout/Navbar/NavbarCustom";
import Footer from "../../Components/Layout/Footer/Index";
import { useParams } from "react-router-dom";
import { LoaderCircle } from "../../Components/Layout/Card/CardLoader";
import serverAPI from "../../config/serverAPI";

function Index() {
  const { slug } = useParams();
  const [processedContent, setProcessedContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serverAPI.get(`/records/videos/${slug}/nt/`);
        const videoData = response.data?.url; // HTML content with iframe tag

        if (videoData) {
          // Parse the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(videoData, "text/html");

          // Add custom classes to iframe tags
          const iframes = doc.querySelectorAll("iframe");
          iframes.forEach((iframe) => {
            iframe.classList.add("custom-iframe-class");
          });

          // Add custom classes to h1 and img tags
          const headings = doc.querySelectorAll("h1");
          headings.forEach((text) => {
            text.classList.add("custom_text_class");
          });

          const images = doc.querySelectorAll("img");
          images.forEach((img) => {
            img.classList.add("custom-img-class");
          });

          // Serialize the modified HTML back to a string
          setProcessedContent(doc.body.innerHTML);
        } else {
          setProcessedContent("<p>No content available.</p>");
        }
      } catch (error) {
        console.error("Error fetching video data:", error);
        setProcessedContent("<p>Error loading content.</p>");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  return (
    <>
      {isLoading ? (
        <div className='w-full h-[100vh] flex justify-center items-center'>
          <LoaderCircle />
        </div>
      ) : (
        <>
          <NavbarCustom />
          <div className='mt-24 max-desktop:mt-20 max-tablet:mt-16 mb-8 max-w-[1200px] max-desktop:max-w-[80%] max-tablet:max-w-[100%] m-auto p-5'>
            <div
              dangerouslySetInnerHTML={{
                __html: processedContent,
              }}
            />
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default Index;
