/** @format */

import { Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../../Components/Inputs/InputField";
import { useGetAll } from "../../../Hooks/useGetAll";
import SecondaryButton from "../../../Components/Inputs/secondaryButton";
import { colors } from "../../../Constants/theme";
import serverAPI from "../../../config/serverAPI";

function ViewEnquiry() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState(null);
  const { id } = state;
  const fetch = () => {
    serverAPI
      .get(`/records/contactus/${id}/nt/`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (!data) {
      fetch();
    }
  }, [data]);

  console.log(data, "<======= data fetched ");

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: data?.name || "",
        email: data?.email || "",
        feedback: data?.feedback || "",
      }}
      onSubmit={(values) => {
        // Handle form submission here
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            sx={{
              width: { xs: "100%", md: "90%", lg: "80%" },
              margin: "auto",
              paddingRight: 2,
            }}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            container
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <InputField
                name={"name"}
                disabled
                label={" Name:"}
                placeholder={" your full name"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                disabled
                name={"email"}
                label={" E-mail address:"}
                placeholder={" valid e-mail address"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                disabled
                name={"feedback"}
                label={" Feedback/Message:"}
                placeholder={" your message or provide a feedback"}
                multiline
                rows={9}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <SecondaryButton
                onClick={() => {
                  navigate(-1);
                }}
                className='w-[69px] h-[32px] border border-[#082F49]'
                sx={{
                  height: "44px",
                  width: "100px",
                  border: `1px solid ${colors.primary.dark}`,
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Back
              </SecondaryButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default ViewEnquiry;
