/** @format */

import React from "react";
import { colors } from "../../../Constants/theme";

const Card1 = ({ title, text1, text2, text3, img, imageOnLeft }) => {
  return (
    <div className='flex justify-center w-full'>
      <div className='w-[90%] max-desktop:w-[100%] max-tablet:w-[100%] max-desktop:flex-col-reverse flex  mt-4 py-[1.563rem]'>
        {imageOnLeft ? (
          <>
            <div className='w-[50%] max-desktop:order-2 max-desktop:px-[61px]  max-tablet:px-4 desktop:px-0 max-desktop:w-full flex justify-center'>
              <img src={img} alt='' className='max-tablet:mb-6 ' />
            </div>
            <div className='flex justify-center  max-desktop:w-full w-[50%] p-9 max-desktop:p-0 max-tablet:p-0 max-tablet:px-8 max-desktop:px-[61px] '>
              <div>
                <p
                  className='leading-[50px] max-tablet:leading-[25px] font-playfair font-black text-[3rem] max-desktop:text-[2.2rem] max-tablet:text-[1.5rem] w-full uppercase'
                  style={{
                    background: colors.text.gradient,
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                  }}
                >
                  {title}
                </p>
                <br />
                <p
                  className='leading-5 max-desktop:text-[1.3rem] max-tablet:text-[1.1rem] text-[1.5rem] font-light font-roboto w-[80%] max-desktop:w-full max-tablet:w-full'
                  style={{
                    color: colors.text.dark,
                  }}
                >
                  <span>{text1}</span>
                  <br />
                  <br />
                  <span>{text2}</span>
                  <br />
                  <br />
                  {Array.isArray(text3) ? (
                    <ul
                      style={{ paddingLeft: "1.25rem", listStyleType: "disc" }}
                    >
                      {text3.map((item, index) => (
                        <li key={index} style={{ marginBottom: "0.5rem" }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span>{text3}</span>
                  )}
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='flex justify-center items-center max-desktop:w-full w-[50%] p-9 max-desktop:p-0 max-tablet:p-0 max-tablet:px-8 max-desktop:px-[61px]  order-1'>
              <div>
                <p
                  className='leading-[50px] max-tablet:leading-[25px] font-playfair font-black text-[3rem] max-desktop:text-[2.2rem] max-tablet:text-[1.5rem] w-full uppercase'
                  style={{
                    background: colors.text.gradient,
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                  }}
                >
                  {title}
                </p>
                <br />
                <p
                  className='leading-5 max-desktop:text-[1.3rem] max-tablet:text-[1.1rem] text-[1.5rem] font-light font-roboto w-[80%] max-desktop:w-full max-tablet:w-full'
                  style={{
                    color: colors.text.dark,
                  }}
                >
                  <span>{text1}</span>
                  <br />
                  <br />
                  <span>{text2}</span>
                  <br />
                  <br />
                  {Array.isArray(text3) ? (
                    <ul
                      style={{ paddingLeft: "1.25rem", listStyleType: "disc" }}
                    >
                      {text3.map((item, index) => (
                        <li key={index} style={{ marginBottom: "0.5rem" }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span>{text3}</span>
                  )}
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className='w-[50%] max-desktop:w-full max-desktop:px-[61px] max-tablet:px-4 desktop:px-0 flex justify-center order-1'>
              <img src={img} alt='' className='max-tablet:mb-4 ' />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Card1;
