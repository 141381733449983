/** @format */

import { Grid } from "@mui/material";
import React from "react";
import PrimaryButton from "../../Inputs/PrimaryButton";
import { Dialog } from "../../Layout/dialogBox/dialog";
import TextEditor from "../../Inputs/TextEditor/Index";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useCreateOrUpdate } from "../../../Hooks/useCreateOrUpdate";
import { colors } from "../../../Constants/theme";
import { useAlert } from "../../Layout/Alerts/Index";
import SecondaryButton from "../../Inputs/secondaryButton";
import InputField from "../../Inputs/InputField";
import { GrGallery } from "react-icons/gr";

function Index({ onSuccess }) {
  const { mutate, isLoading } = useCreateOrUpdate({
    url: "/records/images/nt/",
    method: "post",
  });
  const addAlert = useAlert();

  return (
    <Dialog
      title={"Add Gallery Sub Pages"}
      onClose={() => {
        // setSrcImg("");
      }}
      button={
        <PrimaryButton
          sx={{
            height: "45px",
            fontSize: "1rem",
            borderRadius: "8px",
            fontWeight: 500,
            color: colors.text.main,
          }}
        >
          Add Galley Sub Pages
          <GrGallery size={20} className='ms-1 text-white' />
        </PrimaryButton>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{
            image_title: "",
            gallery_desc: "",
          }}
          validationSchema={yup.object().shape({
            image_title: yup.string().required("Campaign name is required"),

            gallery_desc: yup
              .string()
              .required("Campaign description is required"),
          })}
          onSubmit={(values) => {
            const formData = new FormData();
            formData.append("image_title", values.image_title);
            formData.append("gallery_desc", values.gallery_desc);
            mutate(formData, {
              onSuccess: () => {
                addAlert("Create galler sub pages successfully!", "success", {
                  vertical: "top",
                  horizontal: "center",
                });
                onSuccess();
                onClose();
                // queryClient.refetchQueries(`/campaign/categories/nt/`);
              },
              onError: (error) => {
                const errorMessages = error.response?.data
                  ? Object.values(error.response.data).flat().join("\n")
                  : "An unexpected error occurred.";

                addAlert(
                  errorMessages,
                  "error",
                  {
                    vertical: "top",
                    horizontal: "center",
                  },
                  3000
                );
              },
            });
          }}
        >
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <InputField
                  required={true}
                  name='image_title'
                  label='Page title:'
                  placeholder='Enter Page title'
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextEditor
                  name='gallery_desc'
                  label={"Gallery Description:"}
                  required={true}
                />
              </Grid>
              <Grid
                item
                mt={2}
                gap={4}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <SecondaryButton
                  onClick={onClose}
                  className='w-[69px] h-[32px] border border-[#082F49]'
                  sx={{
                    height: "44px",
                    width: "100px",
                    border: `1px solid ${colors.primary.dark}`,
                    borderRadius: "5px",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  type='submit'
                  isLoading={isLoading}
                  sx={{
                    height: "44px",
                    width: "100px",
                    borderRadius: "5px",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  Save
                </PrimaryButton>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
}

export default Index;
